import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import useSiteMetadata from "../components/SiteMetadata";

export const CheatsheetPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  helmet,
  image
}) => {
  const PostContent = contentComponent || Content;
  const fixedImage = image && image.childImageSharp.fixed ? image.childImageSharp.fixed : { width: 1110 };

  return (
    <div className="uk-grid">
      {helmet || ''}
      <div className="uk-width-1-1">
        <div className="main uk-width-auto section" id="main">
          <div className="widget Blog" data-version="2" id="Blog1">
            <div className="single_post hfeed" id="single_post">
              <article className="post hentry">
                <div className="post_content">
                  <h1 className="post_title entry-title uk-article-title">
                    {title}
                  </h1>
                  <div className="post_body entry-content uk-margin-large-bottom" id="post_body_6501734076252104728">
                    {
                      image && image.childImageSharp.fluid && image.childImageSharp.fixed && fixedImage.width < 1110
                        ? <img
                          src={image.childImageSharp.fluid.src}
                          alt={title}
                          width={fixedImage.width}
                          height={fixedImage.height}
                          style={{ borderRadius: '5px' }}
                        />
                        : <PreviewCompatibleImage
                          imageInfo={{
                            image: image,
                            alt: title,
                          }}
                        />
                    }
                    <p>
                      <PostContent content={content} />
                    </p>
                  </div>
                  {tags && tags.length ? (
                    <div className="post_footer uk-article-meta">
                      <div className="post_footer_line post_footer_line_1">
                        <span className="post_labels uk-margin-right uk-margin-small-bottom">
                          <span className="post_labels_label uk-margin-xsmall-right">
                            <span className="uk-icon uk-margin-small-right" data-uk-icon="icon:tag;ratio:.75">
                              <svg width="15"
                                   height="15"
                                   viewBox="0 0 20 20"
                                   xmlns="http://www.w3.org/2000/svg"
                                   data-svg="tag"><path
                                fill="none" stroke="#000" stroke-width="1.1"
                                d="M17.5,3.71 L17.5,7.72 C17.5,7.96 17.4,8.2 17.21,8.39 L8.39,17.2 C7.99,17.6 7.33,17.6 6.93,17.2 L2.8,13.07 C2.4,12.67 2.4,12.01 2.8,11.61 L11.61,2.8 C11.81,2.6 12.08,2.5 12.34,2.5 L16.19,2.5 C16.52,2.5 16.86,2.63 17.11,2.88 C17.35,3.11 17.48,3.4 17.5,3.71 L17.5,3.71 Z"></path><circle
                                cx="14" cy="6" r="1"></circle></svg></span>Štítky</span>
                          {tags.map((tag, index) => (
                            <>
                              <Link to={`/tags/${kebabCase(tag)}/`} rel="tag" key={tag + `tag`}>#{tag}</Link>
                              {index < (tags.length - 1) && <span className='uk-margin-xsmall-right'>,</span>}
                            </>
                          ))}
                        </span>
                      </div>
                    </div>
                  ) : null}
                </div>
              </article>
            </div>
            <div className='blog_pager uk-text-center uk-margin-large-top'>
              <Link className='blog-pager-older-link uk-button uk-button-default'
                    to='/tahaky' title='Prohlédnout další taháky'>
                <span className='uk-margin-small-right'>Prohlédnout další taháky</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/*
<div className="uk-grid">
  <div className="uk-width-1-1">
    <div className="main uk-width-auto section" id="main">
      <div className="widget Blog" data-version="2" id="Blog1">
        <div className="single_post hfeed" id="single_post">
          <article className="post hentry">
            <div className="post_content">
              <h1 className="post_title entry-title uk-article-title">Cards Could Help You Win the Game</h1>
              <div className="post_body entry-content uk-margin-large-bottom" id="post_body_6501734076252104728"
                   data-uk-lightbox="toggle:a[imageanchor]">
                <a
                  href="https://1.bp.blogspot.com/-btp01YvLkeU/V0tLoKOMY_I/AAAAAAAAD6w/RkDFgrZRnMsfoChkuWdqZl3r8b-6q2LcQCLcB/s1600/1.jpg"
                  imageanchor="1"><img border="0"
                                       src="https://1.bp.blogspot.com/-btp01YvLkeU/V0tLoKOMY_I/AAAAAAAAD6w/RkDFgrZRnMsfoChkuWdqZl3r8b-6q2LcQCLcB/s1600/1.jpg"></a>
                <p style="text-align: center;">Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus
                  eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla
                  vitae elit libero, a pharetra augue. Nullam id dolor id nibh ultricies vehicula</p> <p
                style="text-align: center;">ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-1-2@m uk-first-column"><a
                    href="https://2.bp.blogspot.com/-EWKYEUgUzwE/V0rHfZPRTkI/AAAAAAAAD6g/5dpz37A_9bYQGLLOBx9i3ulM3JtXIU3mwCLcB/s1600/2.jpg"
                    imageanchor="1"><img border="0"
                                         src="https://2.bp.blogspot.com/-EWKYEUgUzwE/V0rHfZPRTkI/AAAAAAAAD6g/5dpz37A_9bYQGLLOBx9i3ulM3JtXIU3mwCLcB/s1600/2.jpg"></a>
                  </div>
                  <div className="uk-width-1-2@m"><h3>Kickstart your web development</h3>  <p className="lead">Lorem
                    ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Ut enim ad minim veniam.</p>  <p><a className="uk-button uk-button-success"
                                                                             href="#!">Try the demo</a></p>
                    <div className="uk-grid-medium uk-grid" data-uk-grid="">
                      <div className="uk-width-1-2@s uk-first-column"><h4>Tons Of Elements</h4>       <p>Neque porro
                        quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci.</p></div>
                      <div className="uk-width-1-2@s"><h4>Video Walkthroughs</h4>       <p>Neque porro quisquam est, qui
                        dolorem ipsum quia dolor sit amet, consectetur, adipisci.</p></div>
                    </div>
                  </div>
                </div>
                <div className="uk-grid-medium uk-grid" data-uk-grid="">
                  <div className="uk-width-1-2@s uk-first-column"><a
                    href="https://4.bp.blogspot.com/-tgV3jJYYNw4/V0tLu1E05MI/AAAAAAAAD60/GlkytQ4uFhUSffnChEaL23-jVO10ZoLmACLcB/s1600/3.jpg"
                    imageanchor="1"><img className="img-responsive" data-selector="img"
                                         src="https://4.bp.blogspot.com/-tgV3jJYYNw4/V0tLu1E05MI/AAAAAAAAD60/GlkytQ4uFhUSffnChEaL23-jVO10ZoLmACLcB/s1600/3.jpg"></a>
                  </div>
                  <div className="uk-width-1-2@s"><a
                    href="https://2.bp.blogspot.com/-p45xBKmNw-Q/V09I2tdQLiI/AAAAAAAAD7U/GVt4MJ8Irpcm64ViexnBGvRB8sbv8XFFACLcB/s1600/4.jpg"
                    imageanchor="1"><img className="img-responsive" data-selector="img"
                                         src="https://2.bp.blogspot.com/-p45xBKmNw-Q/V09I2tdQLiI/AAAAAAAAD7U/GVt4MJ8Irpcm64ViexnBGvRB8sbv8XFFACLcB/s1600/4.jpg"></a>
                  </div>
                </div>
                <p style="text-align: center;"> Nullam quis risus eget urna mollis ornare vel eu leo. Nullam quis risus
                  eget urna mollis ornare vel eu leo. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla
                  vitae elit libero, a pharetra augue. Nullam id dolor id nibh ultricies vehicula </p> <p
                style="text-align: center;"> ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
*/

CheatsheetPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

const CheatsheetPost = ({ data, location }) => {
  const { host } = useSiteMetadata();
  const { markdownRemark: post } = data;

  const metaTitle = `${post.frontmatter.title} | Taháky | Angličtina s Pájou`;
  const metaUrl = `${host}${location.pathname}`;
  const metaImagePath = `${host}${post.frontmatter.ogimage.childImageSharp.fluid.src}/`;

  return (
    <Layout>
      <CheatsheetPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            <title>{metaTitle}</title>
            <link rel="canonical" href={metaUrl} />
            <meta name="description" content=""/>
            <meta name="image" content={metaImagePath} />
            <meta property="og:title" content={metaTitle}/>
            <meta property="og:description" content=""/>
            <meta property="og:url" content={metaUrl}/>
            <meta property="og:image" content={metaImagePath}/>
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content="" />
            <meta name="twitter:image" content={metaImagePath} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage}
      />
    </Layout>
  )
}

CheatsheetPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default CheatsheetPost

export const pageQuery = graphql`
  query CheatsheetPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        tags
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1110, quality: 100) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 1110, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ogimage: featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
